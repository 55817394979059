<template>
    <div class="detect_detail_wrap">
        <!--        <div>
                    <img :src="returnItemImage()" alt="">
                </div>-->
        <div class="detect_main_wrap">
            <div class="detect_header_wrap">
                <span>{{ $t('Item_search') }}</span>
            </div>
            <div class="detect_content_wrap">
                <div class="image_wrap">
                    <div class="d_img">
                        <img :src="returnItemImage()">
                    </div>
                </div>
                <div class="d_inform_wrap">
                    <div class="d_inform_header">
                        <div class="d_header_text">
                            {{ $t('detect_img_title') }}
                        </div>
                        <div class="d_header_text2">
                            {{ returnItem().i_name }}
                        </div>

                    </div>
                    <div class="d_inform_bottom">
                        <div class="d_img_date" v-if="checkDate()">
                            <div class="img_date">
                                {{ $t('detect_img_filming_date') }}
                            </div>
                            <div class="d_date">
                                {{ returnDateFormat(returnItem().imo_date, 'YYYY.MM.DD') }}
                            </div>
                        </div>

                        <div class="d_img_date">
                            <div class="img_date">
                                {{ $t('detect_img_create_date') }}
                            </div>
                            <div class="d_date">
                                {{ returnDateFormat(returnItem().created, 'YYYY.MM.DD') }}
                            </div>
                        </div>

                        <div class="d_img_date">
                            <div class="img_date">
                                {{ $t('detect_last_date') }}
                            </div>
                            <div class="d_date">
                                {{ returnLastDetectDate() }}
                            </div>
                        </div>

                        <div class="d_img_date">
                            <div class="img_date">
                                {{ $t('detect_img_number') }}
                            </div>
                            <div class="d_date">
                                {{ returnItem().i_sku }}
                            </div>
                        </div>
                        <div class="d_img_btn">
                            <button @click="runDetection()">
                                {{ returnDetectNumber() == 0 ? $t('detection') : $t('re_detection') }}
                            </button>
                        </div>
                    </div>
                </div>
                <div class="d_search">
                    <button class="ds_text" @click="moveHistory()"
                            v-html="$t('detection_detail_cnt', {cnt: `<span>${returnDetectNumber()}</span>`})">
                        <!--                        검출결과 총 <span>N</span>건-->
                    </button>
                </div>
            </div>
        </div>

        <!--

                    <div>{{$t('detect_img_filming_date')}}</div>
                    <div>{{ returnDateFormat(returnItem().imo_date, 'YYYY.MM.DD') }}</div>
                    <div>{{$t('detect_img_create_date')}}</div>
                    <div>{{ returnDateFormat(returnItem().created, 'YYYY.MM.DD') }}</div>
                    <div>{{$t('detect_last_date')}}</div>
                    <div>{{ returnLastDetectDate() }}</div>
                    <div>{{$t('detect_img_number')}}</div>
                    <div>NFT관련내용은 추후</div>
                    <button @click="runDetection()">{{ returnDetectNumber() == 0 ? $t('detection') : $t('re_detection') }}</button>
                    <button @click="moveHistory()">{{ $t('detection_detail_cnt', {cnt: returnDetectNumber()}) }}</button>-->

    </div>
</template>

<script>
import util from "@/mixins/util";
import imageOption from "@/mixins/imageOption";
import dateMixins from "@/mixins/dateMixins";
import EventBus from "@/utils/event-bus";
import imageResizeMixins from "@/mixins/imageResizeMixins";
import alertMixins from "@/mixins/alertMixins";

export default {
    name: "DetectionDetailDefaultLayout",
    mixins: [imageOption, dateMixins, imageResizeMixins, alertMixins],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        detectionData: {
            default: () => {
            }
        },
    },
    data() {
        return {}
    },
    beforeRouterEnter() {
    },
    created() {
      this.setMetaTitle();
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        setMetaTitle(){
          if (!util.isEmpty(this.detectionData) && !util.isEmpty(this.detectionData.Item) && this.detectionData.Item[0].hasOwnProperty('originator_nick')) {
            EventBus.$emit('setMetaTitle', this.detectionData.Item[0].i_name, this.detectionData.Item[0].originator_nick);
          } else if (!util.isEmpty(this.detectionData) && !util.isEmpty(this.detectionData.Item)) {
            EventBus.$emit('setMetaTitle', this.detectionData.Item[0].i_name);
          }
        },
        movePage(url) {
            this.$router.push(url)
        },
        returnDetect() {
            if (util.isEmpty(this.detectionData.Detect)) {
                return {};
            }
            return this.detectionData.Detect;
        },
        returnItem() {
            if (util.isEmpty(this.detectionData.Item)) {
                return {};
            }
            return this.detectionData.Item[0]
        },
        checkDate() {
            if (util.isEmpty(this.detectionData.Item)) {
                return {};
            }
            if (util.isEmpty(this.detectionData.Item[0].imo_date)) {
                return false
            }
            return true
        },
        returnItemImage() {
            if (util.isEmpty(this.returnItem().ItemFile) || util.isEmpty(this.returnItem().ItemFile.Item)) {
                return '';
            }
            return this.setFitWidthImg(this.returnItem().ItemFile.Item, 580);
        },
        returnLastDetectDate() {
            if (util.isEmpty(this.returnDetect().last_update)) {
                return '-'
            }
            return this.returnDateFormat(this.returnDetect().last_update, 'YYYY.MM.DD')
        },
        returnDetectNumber() {
            return util.isEmpty(this.returnDetect().cnt) ? 0 : util.Number.numFormat(this.returnDetect().cnt);
        },
        runDetection() {
            this.createConfirm({
                content: this.$t('check_detection', {'mingle': 100}),
                confirmText: this.$t('yes'),
                cancelText: this.$t('cancel'),
                confirm: () => {
                    EventBus.$emit('runItemDetection');
                }
            })
        },
        moveHistory() {
            if (this.returnDetectNumber() == 0) {
                return false;
            }
            this.$router.push(`/detection/${this.returnDetect().d_idx}/history`);
        },
    },
    watch: {
      "detectionData": {
        deep: true,
        handler: function (val, oldVal) {
          this.setMetaTitle();
        }
      },
    },
}
</script>

<style scoped>

</style>
